import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
  responseType: 'json',
});

// Utility to check if the token is expiring soon
function isTokenExpiringSoon(expiryUnix: number): boolean {
  // Get current time in Unix time and check if the token expires within the next 5 minutes
  const currentTime = Math.floor(Date.now() / 1000); // Convert milliseconds to seconds
  return expiryUnix - currentTime < 5 * 60; // 5 minutes buffer
}

apiClient.interceptors.request.use(
  async (config) => {
    try {
      const session = await fetchAuthSession();
      const accessToken = session.tokens?.accessToken?.toString();
      const expiryTime = session.tokens?.accessToken?.payload?.exp;

      if (accessToken && expiryTime && !isTokenExpiringSoon(expiryTime)) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      } else {
        console.warn(
          'Access token is expiring soon or not available. Refreshing token...'
        );
        // Force refresh the token
        const refreshedSession = await fetchAuthSession({ forceRefresh: true });
        console.log('refreshedSession', refreshedSession);
        const refreshedToken = refreshedSession.tokens?.accessToken?.toString();
        if (refreshedToken) {
          config.headers.Authorization = `Bearer ${refreshedToken}`;
          console.log('Refreshed Access Token set');
        } else {
          console.warn('Failed to refresh token');
        }
      }
    } catch (error) {
      console.error('Error fetching or refreshing auth token', error);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default apiClient;
