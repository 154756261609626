import clsx from 'clsx';
import ArrowRightIcon from '../../icons/ArrowRightIcon';
import { useBreadcrumb } from '../../context/breadcrumbs';

type Props = {
    title: string;
    onClick?: () => void;
    hasNestedItem?: boolean;
};

const BreadcrumbItem = ({ title, onClick, hasNestedItem }: Props) => {
    return (
        <li className="items-center">
            <div
                className={clsx(
                    'inline-flex items-center text-sm font-medium dark-blue cursor-pointer',
                    !hasNestedItem && 'dark:text-light-gray'
                )}
                onClick={onClick}
            >
                {title}
                {hasNestedItem && <ArrowRightIcon className="w-4 h-4 text-dark-black ms-1" />}
            </div>
        </li>
    );
};

const BreadcrumbComponent = () => {
    const { breadcrumbs, removeBreadcrumbsAfter } = useBreadcrumb();

    return (
        <nav className="flex bg-white pl-1" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                {breadcrumbs.map((breadcrumb, index) => (
                    <BreadcrumbItem
                        key={`${breadcrumb.id}-${breadcrumb.url}`}
                        title={breadcrumb.name}
                        onClick={() => removeBreadcrumbsAfter(index)}
                        hasNestedItem={index < breadcrumbs.length - 1}
                    />
                ))}
            </ol>
        </nav>
    );
};

export default BreadcrumbComponent;
