type Props = {
    children: React.ReactNode;
    className?: string;
}

const GridItem = ({ children, className = "col-span-3" }: Props) => (
    <div className={className}>
        {children}
    </div>
);

export default GridItem;