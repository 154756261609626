import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBreadcrumb } from "../../context/breadcrumbs";
import Checkbox from "../basics/Checkbox";
import { ValidationRequest, Page } from "../../types";
import ExclamationMarkInfo from "../../icons/ExclamationMarkInfo";
import { formatDate } from "../../utils/formatDate";

type Props = {
    items: ValidationRequest[] | Page[];
    isDetail: boolean;
    onRowClick?: (drawing_file_id: string) => void;
    onCheckboxChange?: (id: string, checked: boolean) => void;
};

const ValidationRequestTable = ({ isDetail, items, onRowClick, onCheckboxChange }: Props) => {
    const navigate = useNavigate();
    const { clearBreadcrumbs } = useBreadcrumb();
    const [checkedItem, setCheckedItem] = useState<string | null>(null);

    useEffect(() => {
        clearBreadcrumbs();
    }, [clearBreadcrumbs]);

    const handleCheckboxChange = (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onCheckboxChange) {
            const checked = event.target.checked;
            setCheckedItem(checked ? id : null);
            onCheckboxChange(id, checked);
        }
    };

    const handleRowClick = (drawing_file_id: string) => () => {
        if (onRowClick) {
            onRowClick(drawing_file_id);
        }
    };

    return (
        <div className="table-container shadow-md sm:rounded-lg no-scrollbar">
            <table className="table text-sm text-left rtl:text-right font-sans">
                <thead className="thead text-sm bg-light-blue">
                    <tr>
                        {!isDetail && (
                            <th scope="col" className="px-6 py-3 whitespace-nowrap"></th>
                        )}
                        {isDetail ? (
                            <>
                                <th scope="col" className="px-6 py-3 whitespace-nowrap">Sheet number</th>
                                <th scope="col" className="px-6 py-3 whitespace-nowrap">Sheet title</th>
                                <th scope="col" className="px-6 py-3 whitespace-nowrap">Page Number</th>
                                <th scope="col" className="px-6 py-3 whitespace-nowrap">Issues</th>
                            </>
                        ) : (
                            <>
                                <th scope="col" className="px-6 py-3 whitespace-nowrap">Title</th>
                                <th scope="col" className="px-6 py-3 whitespace-nowrap">Validation type</th>
                                <th scope="col" className="px-6 py-3 whitespace-nowrap">Created at</th>
                                <th scope="col" className="px-6 py-3 whitespace-nowrap">Status</th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {isDetail ? (
                        items?.map((item, index) => {
                            const { id, sheet_number, sheet_title, page_number, issues } = item as Page;
                            return (
                                <tr key={`${id}-${index}`}
                                    onClick={handleRowClick(id)}
                                    className="bg-white border-b border-gray-30 cursor-pointer hover:bg-gray-50"
                                >
                                    <td className="px-6 py-4 whitespace-nowrap">{sheet_number}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{sheet_title}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{page_number}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {issues?.map((issue, index) => (
                                            <div key={`${issue}-${index}`} className="flex items-center">
                                                {issue?.count}
                                                <ExclamationMarkInfo className="h-4 w-4 text-orange-400 ml-1" />
                                            </div>
                                        ))}
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        items?.map((item, index) => {
                            const { id, title, type, created_at, status } = item as ValidationRequest;
                            const rowId = `${id}`;
                            return (
                                <tr key={`${id}-${index}`} className="bg-white border-b border-gray-30 cursor-pointer hover:bg-gray-50">
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <Checkbox
                                            id={rowId}
                                            checked={checkedItem === rowId}
                                            onChange={handleCheckboxChange(rowId)}
                                            label=""
                                        />
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-blue-500" onClick={() => navigate(`/validation-request/${id}`)}>{title}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{type}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{formatDate(created_at)}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{status}</td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default ValidationRequestTable;

