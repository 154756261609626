import { useState, useRef } from 'react';
import { useAuthenticator } from "@aws-amplify/ui-react-core";
import { useUsers } from '../../hooks/useUsers';
import useOutsideClick from '../../hooks/useOutsideClick';
import ListItem from '../basics/ListItem';
import Dropdown from '../basics/Dropdown';
import Button from '../basics/Button';
import LogoutIcon from "../../icons/LogoutIcon";
import UserIcon from "../../icons/UserIcon";
import EditIcon from '../../icons/EditIcon';
import SettingsIcon from '../../icons/SettingsIcon';
import HelpIcon from '../../icons/HelpIcon';
import AvatarIcon from '../../icons/AvatarIcon';

const UserButton = () => {
  const { signOut } = useAuthenticator();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useOutsideClick(dropdownRef, () => setIsOpen(false));

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const { userEmail, hasUserEmail } = useUsers();

  return (
    <Dropdown
      dropdownRef={dropdownRef}
      toggleDropdown={toggleDropdown}
      icon={<UserIcon className="w-5 h-5 text-dark-black" />}
      isOpen={isOpen}
    >
      {hasUserEmail && (
        <>
          <div
            className="p-4 rounded-md font-normal"
          >
            <div className='flex justify-center'>
              <AvatarIcon className="w-6 h-6" />
            </div>
            <div className="text-[16px] font-medium flex justify-center text-center">
              {userEmail}
            </div>
            <div className="mt-2 border-t border-gray-200" />
          </div>
          <ListItem className="p-2 rounded-md font-normal">
            <div className="flex items-center justify-center">
              <EditIcon className="w-4 h-4 mr-2" />
              <div className="text-[16px] font-medium flex justify-center text-center">Edit profile</div>
            </div>
          </ListItem>
          <ListItem className="p-2 rounded-md font-normal">
            <div className="flex items-center justify-center">
              <SettingsIcon className="w-4 h-4 mr-2" />
              <div className="text-[16px] font-medium flex justify-center text-center">Settings</div>
            </div>
          </ListItem>
          <ListItem className="p-2 rounded-md font-normal">
            <div className="flex items-center justify-center">
              <HelpIcon className="w-4 h-4 mr-2" />
              <div className="text-[16px] font-medium flex justify-center text-center">Help</div>
            </div>
          </ListItem>
        </>
      )}
      <Button
        rounded
        className="mt-2 w-full flex justify-center"
        startIcon={<LogoutIcon className='w-4 h-4 text-white' />}
        onClick={signOut}
      >
        Logout
      </Button>
    </Dropdown>
  );
};

export default UserButton;
