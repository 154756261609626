import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./styles/globals.css";
import { Amplify } from "aws-amplify";
import '@aws-amplify/ui-react/styles.css';
import 'react-modern-drawer/dist/index.css'
import reportWebVitals from './reportWebVitals';
import PageNotFound from './components/PageNotFound';
import ValidationRequestDetail from './components/validation-requests/ValidationRequestDetail';
import ValidationRequestDrawingDetail from './components/validation-requests/ValidationRequestDrawingDetail';
import AddValidationRequest from './components/validation-requests/AddValidationRequest';
import HomePage from './components/HomePage';
import AuthWrapper from './components/auth/AuthWrapper';
import { PdfFocusProvider } from "./context/pdf";
import { BreadcrumbProvider } from './context/breadcrumbs';
import "./styles/globals.css";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID ?? "",
      userPoolId: process.env.REACT_APP_USER_POOL_ID ?? "",
      loginWith: {
        oauth: {
          domain: process.env.OAUTH_DOMAIN ?? "",
          scopes: ['email'],
          redirectSignIn: [process.env.REACT_APP_REDIRECT_SIGN_IN ?? ""],
          redirectSignOut: [process.env.REACT_APP_REDIRECT_SIGN_OUT ?? ""],
          responseType: 'token',
        },
        username: false,
        email: true,
        phone: false,
      }
    }
  }
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthWrapper>
      <QueryClientProvider client={queryClient}>
        <PdfFocusProvider>
          <BreadcrumbProvider>
            <ToastContainer autoClose={1000} />
            <BrowserRouter>
              <Routes>
                <Route path="*" element={<PageNotFound />} />
                <Route path="/" element={<HomePage />} />
                <Route path="/add-validation-request" element={<AddValidationRequest />} />
                <Route path="/validation-request/:validationRequestId" element={<ValidationRequestDetail />} />
                <Route path="/validation-request/:validationRequestId/:drawingId" element={<ValidationRequestDrawingDetail />} />
              </Routes>
            </BrowserRouter>
          </BreadcrumbProvider>
        </PdfFocusProvider>
      </QueryClientProvider>
    </AuthWrapper>
  </React.StrictMode>
);

// Performance monitoring
reportWebVitals();