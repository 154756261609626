import { PDFDocument, rgb, PDFName, PDFString, PDFRef, PDFArray } from 'pdf-lib';

export async function addLinkAnnotationsToPdf({
  existingPdfBytes,
  annotations,
  rotation = 0,
}: {
  existingPdfBytes: Uint8Array;
  annotations: Array<{
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    color: ReturnType<typeof rgb>,
    borderWidth: number,
    target: string | number,
    isExternal: boolean,
    isValid: boolean | undefined,
  }>,
  rotation: number,
}) {
  // Load the existing PDFDocument
  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  // Get the first page of the document
  const page = pdfDoc.getPage(0);
  const pageHeight = page.getHeight();
  const pageWidth = page.getWidth();

  // Initialize the annotations array
  const annotationsArray = (page.node.Annots() as PDFArray | undefined)?.asArray() as PDFRef[] || [];

  // Loop through each annotation
  for (const annotation of annotations) {
    const { x1, y1, x2, y2, color, borderWidth, target, isExternal, isValid } = annotation;

    // Adjust coordinates based on the rotation
    let adjustedX1 = x1 * pageWidth;
    let adjustedY1 = (1 - y1) * pageHeight;
    let adjustedX2 = x2 * pageWidth;
    let adjustedY2 = (1 - y2) * pageHeight;

    switch (rotation) {
      case 90:
        adjustedX1 = pageWidth - y1 * pageHeight;
        adjustedY1 = x1 * pageWidth;
        adjustedX2 = pageWidth - y2 * pageHeight;
        adjustedY2 = x2 * pageWidth;
        break;
      case 180:
        adjustedX1 = pageWidth - x1 * pageWidth;
        adjustedY1 = pageHeight - y1 * pageHeight;
        adjustedX2 = pageWidth - x2 * pageWidth;
        adjustedY2 = pageHeight - y2 * pageHeight;
        break;
      case 270:
        adjustedX1 = y1 * pageHeight;
        adjustedY1 = pageHeight - x1 * pageWidth;
        adjustedX2 = y2 * pageHeight;
        adjustedY2 = pageHeight - x2 * pageWidth;
        break;
      case 0:
      default:
        // No adjustment needed
        break;
    }

    // Extract color components
    const { red, green, blue } = color;

    if (!isValid) {
      // Draw a rectangle for invalid elements
      page.drawRectangle({
        x: adjustedX1,
        y: adjustedY1,
        width: adjustedX2 - adjustedX1,
        height: adjustedY2 - adjustedY1,
        borderColor: color,
        borderWidth: borderWidth,
      });
      continue; // Skip creating a link annotation
    }

    // Create a link annotation based on the isExternal flag
    const linkAnnotation = isExternal
      ? page.doc.context.register(
        page.doc.context.obj({
          Type: 'Annot',
          Subtype: 'Link',
          Rect: [adjustedX1, adjustedY1, adjustedX2, adjustedY2],
          Border: [0, 0, borderWidth],
          C: [red, green, blue],
          A: {
            Type: 'Action',
            S: 'URI',
            URI: PDFString.of(target as string),
          },
        })
      )
      :
      page.doc.context.register(
        page.doc.context.obj({
          Type: 'Annot',
          Subtype: 'Link',
          Rect: [adjustedX1, adjustedY1, adjustedX2, adjustedY2],
          Border: [0, 0, borderWidth],
          C: [red, green, blue],
          A: {
            Type: 'Action',
            S: 'GoTo',
            D: [
              pdfDoc.getPage(target as number).ref,
              PDFName.of('FitH'),
              0,
            ],
          },
        })
      );

    // Add the annotation to the annotations array
    annotationsArray.push(linkAnnotation);
  }

  // Set the annotations array to the page
  page.node.set(PDFName.of('Annots'), pdfDoc.context.obj(annotationsArray));

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await pdfDoc.save();

  // Create a blob from the pdfBytes
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });

  // Return the blob
  return { blob };
}
