// Users query keys
export const USER_RESULT_QUERY_KEY = 'singleUserDetails';

// Request validation keys
export const REQUEST_VALIDATION_QUERY_KEY = 'requestValidation';

export const REQUEST_VALIDATION_LIST_QUERY_KEY = 'requestValidationList';

export const REQUEST_VALIDATION_PAGES_QUERY_KEY = 'requestValidationPages';

export const REQUEST_VALIDATION_PAGE_QUERY_KEY = 'requestValidationPage';

export const REQUEST_VALIDATION_MARKERS_QUERY_KEY = 'requestValidationMarkers';

export const REQUEST_VALIDATION_REPORT_QUERY_KEY = 'requestValidationReport'