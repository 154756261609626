import React from "react";
import Navigation from "../navigation/Navigation";

type Props = {
  children: React.ReactNode;
  isDetailPage?: boolean;
};

const DefaultLayout = ({ children }: Props) => {
  return (
    <>
      <Navigation />
      <div className="h-screen overflow-x-auto no-scrollbar">
        <div className="mx-auto px-10 py-10">
          {children}
        </div>
      </div>
    </>
  )
};

export default DefaultLayout;