import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "../../icons/ArrowBackIcon";

const BackButton = () => {
    const navigate = useNavigate();
    return (
        <div
            onClick={() => navigate(- 1)}
            className="cursor-pointer flex items-center"
        >
            <ArrowBackIcon className="w-4 h-4 mr-2" />
            <div>Back</div>
        </div >
    );
}

export default BackButton;