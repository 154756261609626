import NotificationButton from "./NotificationButton";
import UserButton from "./UserButton";
// import logo from "../../assets/twinknowledge_logo.jpeg";
import TollBrothersLogo from "../../assets/TollBrothers.png";
import { useNavigate } from "react-router-dom";

const Navigation = () => {
  const navigate = useNavigate();
  return (
    <nav className="p-1.5 flex justify-between items-center bg-light-blue text-dark-black shadow-md">
      <div className="flex items-center font-sans">
        {/* <img src={logo} alt="Twinknowledge Logo" className="h-12 mr-2 cursor-pointer border rounded-md" onClick={() => navigate("/")} /> */}
        {/* <TollBrothersIcon className="h-12 w-20 mr-2 cursor-pointer border rounded-md bg-white" onClick={() => navigate("/")} /> */}
        <img
          src={TollBrothersLogo}
          alt="TollBrothers Logo"
          className="h-10 mr-8 cursor-pointer rounded-md"
          onClick={() => navigate("/")}
        />
        <div>
          <div className="text-lg font-bold italic text-gradient">Architectural plans validation</div>
          <div className="flex justify-end text-sm italic text-gradient">by TwinKnowledge</div>
        </div>
      </div>
      <div className="flex items-center">
        <NotificationButton />
        <UserButton />
      </div>
    </nav>
  );
};

export default Navigation;
