import { createContext, useContext, useState, useCallback, ReactNode, useEffect } from 'react';

export type Breadcrumb = {
    id: string;
    name: string;
    url: string;
    drawingMarkerId: string;
};


type BreadcrumbContextType = {
    breadcrumbs: Breadcrumb[];
    addBreadcrumb: (breadcrumb: Breadcrumb) => void;
    removeBreadcrumbsAfter: (index: number) => void;
    clearBreadcrumbs: () => void;
    hasNextBreadcrumb: boolean;
    activeBreadcrumb: Breadcrumb | null;
};

// Create the context with an initial value
const BreadcrumbContext = createContext<BreadcrumbContextType | undefined>(undefined);

const BREADCRUMB_STORAGE_KEY = 'breadcrumbState';

// Helper function to save breadcrumbs to local storage
const saveBreadcrumbsToLocalStorage = (breadcrumbs: Breadcrumb[]) => {
    localStorage.setItem(BREADCRUMB_STORAGE_KEY, JSON.stringify(breadcrumbs));
};

// Helper function to load breadcrumbs from local storage
const loadBreadcrumbsFromLocalStorage = (): Breadcrumb[] => {
    const savedBreadcrumbs = localStorage.getItem(BREADCRUMB_STORAGE_KEY);
    return savedBreadcrumbs ? JSON.parse(savedBreadcrumbs) : [];
};

// Create a provider component
export const BreadcrumbProvider = ({ children }: { children: ReactNode }) => {
    const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>(loadBreadcrumbsFromLocalStorage());
    const [activeBreadcrumb, setActiveBreadcrumb] = useState<Breadcrumb | null>(breadcrumbs[breadcrumbs.length - 1] || null);

    useEffect(() => {
        saveBreadcrumbsToLocalStorage(breadcrumbs);
    }, [breadcrumbs]);

    // Function to add a breadcrumb
    const addBreadcrumb = useCallback((breadcrumb: Breadcrumb) => {
        setBreadcrumbs((prev) => {
            const breadcrumbExists = prev.some(b => b.id === breadcrumb.id && b.url === breadcrumb.url);
            if (!breadcrumbExists) {
                const updatedBreadcrumbs = [...prev, breadcrumb];
                setActiveBreadcrumb(breadcrumb);
                return updatedBreadcrumbs;
            }
            return prev;
        });
    }, []);


    // Function to remove breadcrumbs after a specific index
    const removeBreadcrumbsAfter = useCallback((index: number) => {
        setBreadcrumbs((prev) => {
            const updatedBreadcrumbs = prev.slice(0, index + 1);
            setActiveBreadcrumb(updatedBreadcrumbs[updatedBreadcrumbs.length - 1] || null);
            return updatedBreadcrumbs;
        });
    }, []);

    // Function to clear all breadcrumbs
    const clearBreadcrumbs = useCallback(() => {
        setBreadcrumbs([]);
        setActiveBreadcrumb(null);
        localStorage.removeItem(BREADCRUMB_STORAGE_KEY);
    }, []);

    // Check if there are more breadcrumbs
    const hasNextBreadcrumb = breadcrumbs.length > 1;

    return (
        <BreadcrumbContext.Provider value={{ breadcrumbs, addBreadcrumb, removeBreadcrumbsAfter, clearBreadcrumbs, hasNextBreadcrumb, activeBreadcrumb }}>
            {children}
        </BreadcrumbContext.Provider>
    );
};

// Hook to use the breadcrumb context
export const useBreadcrumb = () => {
    const context = useContext(BreadcrumbContext);
    if (!context) {
        throw new Error('useBreadcrumb must be used within a BreadcrumbProvider');
    }
    return context;
};

