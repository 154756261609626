import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import DefaultLayout from "../layout/DefaultLayout";
import Button from "../basics/Button";
import Input from "../basics/Input";
import FileUpload from "../basics/FileUpload";
import BackButton from "../basics/BackButton";
import Grid from "../basics/Grid";
import GridItem from "../basics/GridItem";
import { useRequestValidation } from '../../hooks/useRequestValidation';
import clsx from 'clsx';
import Select from '../basics/Select';

const schema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    validation_type: Yup.string().required("Validation type is required"),
    files: Yup.array().min(1).required("File is required")
});

type FormValues = {
    title: string;
    description: string;
    validation_type: string;
    files: any;
}

const AddValidationRequest = () => {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors } } = useForm<FormValues>({ resolver: yupResolver(schema) });

    const { createValidationRequest, createValidationRequestIsLoading } = useRequestValidation({
        onCreateValidationRequest: () => navigate("/")
    });

    return (
        <DefaultLayout>
            <BackButton />
            <Grid>
                <GridItem className="col-span-12">
                    <form
                        className={clsx("p-4 bg-light-blue shadow-sm", createValidationRequestIsLoading && "pointer-events-none")}
                        onSubmit={handleSubmit((data) => {
                            const formData = new FormData();
                            formData.append('title', data.title);
                            formData.append('description', data.description);
                            formData.append('validation_type', data.validation_type);

                            data.files.forEach((file: any, index: number) => {
                                formData.append(`files`, file);
                            });


                            createValidationRequest(formData);
                        })}
                    >
                        <div className="mb-5">
                            <Input
                                label="Title"
                                {...register("title")}
                                hasError={Boolean(errors?.title?.message)}
                                errorText={errors?.title?.message}
                            />
                        </div>

                        <div className="mb-5">
                            <Select
                                label="Validation type"
                                options={[
                                    { value: "REFERENCES VALIDATION", label: "References validation" },
                                ]}
                                onChange={(e) => {
                                    setValue("validation_type", e.target.value, { shouldValidate: true })
                                }}
                                hasError={Boolean(errors?.validation_type?.message)}
                                errorText={errors?.validation_type?.message}
                                hasPlaceholder
                                placeholderLabel="Choose validation type"
                            />
                        </div>

                        <div className="mb-5">
                            <Input
                                label="Description"
                                {...register("description")}
                                hasError={Boolean(errors?.description?.message)}
                                errorText={errors?.description?.message}
                            />
                        </div>

                        <FileUpload
                            name="files"
                            setValue={setValue}
                            watch={watch}
                            hasError={Boolean(errors?.files?.message)}
                            errorText={errors?.files?.message?.toString()}
                        />

                        <Button
                            type="submit"
                            isLoading={createValidationRequestIsLoading}
                            disabled={createValidationRequestIsLoading}
                            rounded
                            className="w-full flex justify-center"
                        >
                            Submit drawing set
                        </Button>
                    </form>
                </GridItem>
            </Grid>
        </DefaultLayout>
    )
}

export default AddValidationRequest;
