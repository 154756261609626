import clsx from "clsx";
import InfoIcon from "../../icons/InfoIcon";

type Props = {
    type: "info" | "danger" | "success" | "warning";
    message: string;
    showMessageType?: boolean;
    children?: React.ReactNode;
}

const Alert = ({ type = "info", message, showMessageType = false, children }: Props) => {
    const alertStyles = {
        info: "text-blue-800 bg-blue-200",
        danger: "text-red-800 bg-red-200",
        success: "text-green-800 bg-green-200",
        warning: "text-yellow-800 bg-yellow-200",
    };

    return (
        <div className={clsx("p-4 text-sm rounded-lg", alertStyles[type], "max-h-[400px] flex flex-col")} role="alert">
            <div className="flex items-center flex-shrink-0">
                <InfoIcon className="flex-shrink-0 inline w-4 h-4 me-3" />
                <span className="sr-only">{type.charAt(0).toUpperCase() + type.slice(1)}</span>
                <div>
                    {Boolean(showMessageType) && (
                        <span className="font-medium">
                            {type.charAt(0).toUpperCase() + type.slice(1)}
                            alert!
                        </span>
                    )}
                    {message}
                </div>
            </div>
            <div className="mt-2 overflow-auto flex-grow  no-scrollbar">
                {children}
            </div>
        </div>
    );
};

export default Alert;
