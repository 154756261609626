import { ChangeEvent, useRef } from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import clsx from 'clsx';
import Button from './Button';

type Props = {
    name: string;
    setValue: UseFormSetValue<any>;
    watch: UseFormWatch<any>;
    hasError?: boolean;
    errorText?: string | undefined;
};

const FileUpload = ({ name, setValue, watch, hasError, errorText, }: Props) => {
    const files = watch(name) || [];
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const newFiles = Array.from(event.target.files);
            setValue(name, newFiles, { shouldValidate: true });

            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    const removeFile = (index: number) => {
        const newFiles = files.filter((_: File, i: number) => i !== index);
        setValue(name, newFiles);
    };

    return (
        <div className="mb-5">
            <div className="flex items-center justify-center w-full font-sans">
                <label
                    htmlFor="dropzone-file"
                    className={clsx(
                        "flex flex-col items-center justify-center w-full h-64 border-2 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100",
                        hasError ? 'border-red-500' : 'border-gray-300'
                    )}
                >
                    {files.length === 0 ? (
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg
                                className="w-8 h-8 mb-4 text-gray-500"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 16"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                />
                            </svg>
                            <p className="mb-2 text-sm text-gray-500">
                                <span className="font-semibold">Click to upload</span> PDF files
                            </p>
                        </div>
                    ) : (
                        <div className="mt-2">
                            <ul>
                                {files.map((file: File, index: number) => (
                                    <li key={index} className="mt-1 flex justify-between items-center text-sm text-gray-500">
                                        {file.name}
                                        <Button
                                            className="ml-2"
                                            variant='outlined'
                                            color='error'
                                            rounded
                                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                e.preventDefault();
                                                removeFile(index);
                                            }}
                                        >
                                            Remove
                                        </Button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <input
                        ref={fileInputRef}
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                        multiple
                        onChange={handleFileChange}
                    />
                </label>
            </div>
            {hasError && (
                <p className="text-red-500 text-xs italic mt-1">{errorText}</p>
            )}
        </div>
    );
};

export default FileUpload;
