import React from 'react';
import clsx from "clsx";

type Props = {
    label?: string;
    placeholder?: string;
    hasError?: boolean;
    errorText?: string;
    type?: "text" | "password" | "number";
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const Input = React.forwardRef<HTMLInputElement, Props>(({
    label,
    hasError,
    errorText,
    ...restProps
}, ref) => {
    const labelColor = "text-dark-black";
    const inputClasses = `shadow-sm bg-gray-50 border ${hasError ? 'border-red-500' : 'border-gray-300'} text-sm rounded-lg focus:outline-none block w-full p-2.5`;

    return (
        <div className="mb-4">
            {Boolean(label) && (
                <label className={clsx("block mb-2 text-sm font-medium font-sans", labelColor)}>
                    {label}
                </label>
            )}
            <input
                ref={ref}
                className={inputClasses}
                {...restProps}
            />
            {hasError && (
                <p className="text-red-500 text-xs italic mt-1">{errorText}</p>
            )}
        </div>
    )
})

export default Input;
