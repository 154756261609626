export function formatDate(dateStr: string) {
    // Parse the input date string
    const date = new Date(dateStr);

    // Define an array of month names
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    // Get the day, month, and year
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Format the date to the desired output
    return `${day} ${month}, ${year}`;
}