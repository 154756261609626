import React, { createContext, useState, useContext } from "react";
import { type Citation } from "../types";
import { type DocumentType } from "../types";

interface PdfFocusState {
  sourceId?: string;
  documentId: string;
  pageNumber: number;
  citation?: Citation;
}

interface PdfFocusContextProps {
  pdfFocusState: PdfFocusState;
  activePdfUrl: string;
  pdfFiles: DocumentType[];
  setActivePdfUrl: React.Dispatch<React.SetStateAction<string>>;
  setPdfFiles: React.Dispatch<React.SetStateAction<any>>;
  setPdfFocusState: React.Dispatch<React.SetStateAction<PdfFocusState>>;
  resetPdfState: () => void;
}

const PdfFocusContext = createContext<PdfFocusContextProps | undefined>(
  undefined
);

interface PdfFocusProviderProps {
  children: React.ReactNode;
}

export const PdfFocusProvider: React.FC<PdfFocusProviderProps> = ({
  children,
}) => {
  const [pdfFiles, setPdfFiles] = useState([]);
  const [activePdfUrl, setActivePdfUrl] = useState<string>("");
  const [pdfFocusState, setPdfFocusState] = useState<PdfFocusState>({
    sourceId: "",
    documentId: "",
    pageNumber: 0,
  });

  const resetPdfState = () => {
    setPdfFiles([]);
    setActivePdfUrl("");
  };

  return (
    <PdfFocusContext.Provider
      value={{
        pdfFocusState: pdfFocusState,
        activePdfUrl,
        pdfFiles,
        setActivePdfUrl,
        setPdfFiles,
        setPdfFocusState: setPdfFocusState,
        resetPdfState,
      }}
    >
      {children}
    </PdfFocusContext.Provider>
  );
};


export const usePdfFocus = (): PdfFocusContextProps => {
  const context = useContext(PdfFocusContext);
  if (context === undefined) {
    throw new Error("usePDF must be used within a PDFProvider");
  }
  return context;
};
