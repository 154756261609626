import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DefaultLayout from "./layout/DefaultLayout";
import Button from "./basics/Button";
import ValidationRequestTable from "./validation-requests/ValidationRequestTable";
import Select from "./basics/Select";
import ArchitectIcon from "../icons/ArchitectIcon";
import { useRequestValidation } from "../hooks/useRequestValidation";

const EmptyState = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div className=" mt-8 flex justify-center">
                <ArchitectIcon className="w-[240px] h-[240px]" />
            </div>
            <div className="mt-4 flex justify-center font-bold font-sans">Ready to engineer your next big idea?</div>
            <div className="mt-2 mb-3 flex justify-center font-sans">The platform is ready for your innovative engineering solutions! Start by uploading your first validation request now!</div>
            <div className="flex justify-center">
                <Button
                    rounded
                    onClick={() => navigate("/add-validation-request")}
                >
                    Add new
                </Button>
            </div>
        </div>
    );
};

const HomePage = () => {
    const navigate = useNavigate();
    const { validationRequests, validationRequestIsLoading, hasValidationRequests } = useRequestValidation({});
    const [selectedId, setSelectedId] = useState<string | null>(null);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);

    const handleCheckboxChange = (id: string, checked: boolean) => {
        setSelectedId(checked ? id : null);
    };

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOption(event.target.value);
    };

    const handleRunButtonClick = () => {
        if (selectedId) {
            navigate(`/validation-request/${selectedId}`);
        }
    };

    const isRunButtonDisabled = !selectedId || !selectedOption;

    return (
        <DefaultLayout>
            {hasValidationRequests && (
                <>
                    <div className="flex justify-between items-center mb-2">
                        <div className="flex items-center">
                            <div className="font-bold text-md mr-4">Drawing sets</div>
                            <div className="mr-2">
                                <Button
                                    rounded
                                    onClick={() => navigate("/add-validation-request")}
                                >
                                    Add drawing set
                                </Button>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <div className="mr-2">
                                <Select
                                    hasPlaceholder
                                    placeholderLabel="Choose rule"
                                    options={[
                                        { value: "1", label: "Missing drawing standards" },
                                        { value: "2", label: "ADA standards" }
                                    ]}
                                    onChange={handleSelectChange}
                                />
                            </div>
                            <Button
                                disabled={isRunButtonDisabled}
                                rounded
                                onClick={handleRunButtonClick}
                            >
                                Run
                            </Button>
                        </div>
                    </div>
                </>
            )}

            {hasValidationRequests ? (
                <ValidationRequestTable
                    isDetail={false}
                    items={validationRequests}
                    onCheckboxChange={handleCheckboxChange}
                />
            ) : (
                <>
                    {!validationRequestIsLoading && (
                        <EmptyState />
                    )}
                </>
            )}
        </DefaultLayout>
    );
};

export default HomePage;
