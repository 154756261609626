import React from 'react';
import { Authenticator, View, ThemeProvider, Theme, useTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

type Props = {
  children: React.ReactNode;
}

const components = {
  Header() {
    return (
      <>
        <View
          textAlign="center"
          marginTop="50px"
          fontSize="2rem"
          lineHeight="2.5rem"
          fontWeight="600"
        >
          TwinKnowledge - Secure sign in
        </View>
        <View textAlign="center" marginTop="60px" />
      </>
    );
  },

};


const AuthWrapper = ({ children }: Props) => {
  const { tokens } = useTheme();

  const theme: Theme = {
    name: 'TwinKnowledge Theme',
    tokens: {
      components: {
        authenticator: {
          router: {
            borderWidth: '2px',
            borderStyle: "solid",
            borderColor: "#e5e7eb",
            backgroundColor: "#F2F3F9"
          },
          form: {
            padding: `${tokens.space.medium} ${tokens.space.xl} ${tokens.space.medium}`,
          },
        },
        button: {
          primary: {
            backgroundColor: "#120264",
            _hover: {
              backgroundColor: "#120264",
            },
            _active: {
              backgroundColor: "#120264"
            },
            _disabled: {
              backgroundColor: "#120264"
            },
            _loading: {
              backgroundColor: "#120264"
            }
          },
          link: {
            color: "#120264",
            _hover: {
              color: "#120264",
              backgroundColor: "#fff"
            },
            _active: {
              color: "#120264",
              backgroundColor: "#fff"
            },
            _focus: {
              backgroundColor: "#fff",
              borderColor: "#fff"
            }
          },
        },
        fieldcontrol: {
          _focus: {
            boxShadow: `0 0 0 1px #120264`,
          },
        },
      },
    },
  };

  return (
    <div className='dotted-paper-background'>
      <ThemeProvider theme={theme}>
        <div className='relative z-10'>
          <Authenticator hideSignUp components={components}>
            {children}
          </Authenticator>
        </div>
      </ThemeProvider>
    </div>
  );
};

export default AuthWrapper;
