import React from "react";
import clsx from "clsx";

type Props = {
    label?: string;
    options: {
        value: string,
        label: string
    }[];
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    hasError?: boolean;
    errorText?: string;
    hasPlaceholder: boolean;
    placeholderLabel: string;
}

const Select = ({ label, options, onChange, hasError, errorText, hasPlaceholder, placeholderLabel }: Props) => {
    const [selected, setSelected] = React.useState("");

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelected(event.target.value);
        onChange(event);
    };

    return (
        <>
            {label && (
                <label className="block mb-2 text-sm font-medium font-sans">{label}</label>
            )}
            <select
                className={clsx(
                    "bg-gray-50 border text-sm rounded-lg focus:outline-none block w-full p-2.5",
                    hasError ? 'border-red-500' : 'border-gray-300'
                )}
                value={selected}
                onChange={handleChange}
            >
                {hasPlaceholder && (
                    <option value="" disabled hidden>{placeholderLabel}</option>
                )}
                {options?.map(({ value, label }, index) => (
                    <option key={`${value}-${index}`} value={value}>
                        {label}
                    </option>
                ))}
            </select>
            {hasError && (
                <p className="text-red-500 text-xs italic mt-1">{errorText}</p>
            )}
        </>
    );
};

export default Select;

