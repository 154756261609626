import React from "react";

type Props = {
    id: string;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
};

const Checkbox = ({ id, checked, onChange, label }: Props) => {
    return (
        <div className="flex items-center">
            <input
                id={id}
                type="checkbox"
                checked={checked}
                onChange={onChange}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            {label && <label htmlFor={id} className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{label}</label>}
        </div>
    );
};

export default Checkbox;
