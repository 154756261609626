import { useState, useRef } from 'react';
import NotificationIcon from "../../icons/NotificationIcon";
import useOutsideClick from '../../hooks/useOutsideClick';
import ListItem from '../basics/ListItem';
import Dropdown from '../basics/Dropdown';
import AvatarIcon from '../../icons/AvatarIcon';

const notifications = [{ id: 1, text: "New email received.", time: "Jan 9, 2024" },
{ id: 2, text: "New email received.", time: "Jan 7, 2024" }]

const NotificationButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setIsOpen(false));

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Dropdown
      dropdownRef={dropdownRef}
      toggleDropdown={toggleDropdown}
      icon={
        <>
          <NotificationIcon className="w-5 h-5 text-dark-black" />
          {notifications?.length > 0 && (
            <span className="absolute top-[-5px] right-[-5px] bg-red-600 rounded-full w-[14px] h-[14px] flex items-center justify-center text-[10px] text-white">
              {notifications?.length}
            </span>
          )}
        </>
      }
      isOpen={isOpen}
    >
      {notifications?.map(notification => (
        <ListItem key={notification?.id} className="p-2 rounded-md font-normal">
          <div className="flex items-center">
            <AvatarIcon className="w-6 h-6 text-dark-black mr-2" />
            <div>
              <div className="text-[16px] font-medium">{notification?.text}</div>
              <div className="text-[14px] text-light-gray font-medium">{notification?.time}</div>
            </div>
          </div>
        </ListItem>
      ))}
    </Dropdown>
  );
};

export default NotificationButton;
