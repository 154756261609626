import { useQuery, useMutation } from '@tanstack/react-query';
import { toast } from "react-toastify";
import {
    REQUEST_VALIDATION_LIST_QUERY_KEY,
    REQUEST_VALIDATION_QUERY_KEY,
    REQUEST_VALIDATION_PAGES_QUERY_KEY,
    REQUEST_VALIDATION_PAGE_QUERY_KEY,
    REQUEST_VALIDATION_MARKERS_QUERY_KEY,
    REQUEST_VALIDATION_REPORT_QUERY_KEY
} from '../constants/queryKeys';
import apiClient from '../api/apiClient';

type Params = {
    validation_request_id?: string;
    drawing_file_id?: string;
    onCreateValidationRequest?: () => void;
};

export const useRequestValidation = ({
    validation_request_id,
    drawing_file_id,
    onCreateValidationRequest
}: Params) => {

    const { data: validationRequests, isLoading: validationRequestIsLoading } = useQuery({
        queryKey: [REQUEST_VALIDATION_LIST_QUERY_KEY],
        queryFn: async () => {
            const response = await apiClient.get(`api/v1/validationRequests/`);
            return response.data;
        },
        refetchOnWindowFocus: false,
    });

    const { data: validationRequestItem, isLoading: validationRequestItemIsLoading } = useQuery({
        queryKey: [REQUEST_VALIDATION_QUERY_KEY, validation_request_id],
        queryFn: async () => {
            const response = await apiClient.get(`/api/v1/validationRequests/${validation_request_id}`);
            return response.data;
        },
        refetchOnWindowFocus: false,
        enabled: Boolean(validation_request_id)
    });

    const { mutate: createValidationRequest, isPending: createValidationRequestIsLoading } = useMutation({
        mutationFn: (formData: FormData) => {
            return apiClient.post('api/v1/validationRequests/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },
        onSuccess: () => {
            toast.success("Successfully created!");
            onCreateValidationRequest?.();
        }
    });

    const { data: validationRequestPages, isLoading: validationRequestPagesIsLoading } = useQuery({
        queryKey: [REQUEST_VALIDATION_PAGES_QUERY_KEY, validation_request_id],
        queryFn: async () => {
            const response = await apiClient.get(`/api/v1/validationRequests/${validation_request_id}/pages`);
            return response.data;
        },
        refetchOnWindowFocus: false,
        enabled: Boolean(validation_request_id)
    });

    const { data: validationRequestPageItem, isLoading: validationRequestPageItemIsLoading } = useQuery({
        queryKey: [REQUEST_VALIDATION_PAGE_QUERY_KEY, validation_request_id, drawing_file_id],
        queryFn: async () => {
            const response = await apiClient.get(`/api/v1/validationRequests/${validation_request_id}/pages/${drawing_file_id
                }`);
            return response.data;
        },
        refetchOnWindowFocus: false,
        enabled: Boolean(validation_request_id && drawing_file_id)
    });

    const { data: validationRequestMarkers, isLoading: validationRequestMarkersIsLoading } = useQuery({
        queryKey: [REQUEST_VALIDATION_MARKERS_QUERY_KEY, validation_request_id,
            drawing_file_id
        ],
        queryFn: async () => {
            const response = await apiClient.get(`/api/v1/validationRequests/${validation_request_id}/pages/${drawing_file_id}/markers`);
            return response.data;
        },
        refetchOnWindowFocus: false,
        enabled: Boolean(validation_request_id && drawing_file_id)
    });

    const { data: validationRequestReport, isLoading: validationRequestReportIsLoading, } = useQuery({
        queryKey: [REQUEST_VALIDATION_REPORT_QUERY_KEY, validation_request_id],
        queryFn: async () => {
            const response = await apiClient.get(`/api/v1/validationRequests/${validation_request_id}/report`);
            return response.data;
        },
        refetchOnWindowFocus: false,
        enabled: Boolean(validation_request_id)
    });

    return {
        validationRequests,
        validationRequestIsLoading,
        hasValidationRequests: Boolean(validationRequests?.length && !validationRequestIsLoading),
        validationRequestItem,
        hasValidationRequestItem: Boolean(validationRequestItem && !validationRequestItemIsLoading),
        createValidationRequest,
        createValidationRequestIsLoading,
        validationRequestPages,
        hasValidationRequestPages: Boolean(validationRequestPages?.length && !validationRequestPagesIsLoading),
        validationRequestPageItem,
        hasValidationRequestPageItem: Boolean(validationRequestPageItem && !validationRequestPageItemIsLoading),
        validationRequestMarkers,
        validationRequestMarkersIsLoading,
        hasValidationRequestMarkers: Boolean(validationRequestMarkers?.length && !validationRequestMarkersIsLoading),
        validationRequestReport,
        hasValidationRequestReport: Boolean(validationRequestReport?.length && !validationRequestReportIsLoading),
    };
};
