import React from "react";

const ExclamationMarkInfo = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-exclamation-triangle"
        viewBox="0 0 16 16"
        {...props}
    >
        <path d="M7.938 2.016A.13.13 0 018.002 2a.13.13 0 01.063.016.15.15 0 01.054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 01-.054.06.1.1 0 01-.066.017H1.146a.1.1 0 01-.066-.017.2.2 0 01-.054-.06.18.18 0 01.002-.183L7.884 2.073a.15.15 0 01.054-.057m1.044-.45a1.13 1.13 0 00-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"></path>
        <path d="M7.002 12a1 1 0 112 0 1 1 0 01-2 0M7.1 5.995a.905.905 0 111.8 0l-.35 3.507a.552.552 0 01-1.1 0z"></path>
    </svg>
);


export default ExclamationMarkInfo;
