import React, { useEffect, useState } from "react";
import { rgb } from "pdf-lib";
import usePDFViewer from "../../hooks/usePdfViewer";
import type { DocumentType, Marker } from "../../types";
import { addLinkAnnotationsToPdf } from "../../utils/addLinkAnnotationToPdf";
import { getAccessToken } from "../../api/getAcessToken";
import { PDFOptionsBar } from "./PdfOptionsBar";
import MemoizedVirtualizedPDF from "./VirtualizedPdf";
import { Breadcrumb } from "../../context/breadcrumbs";

interface ViewPdfProps {
  file: DocumentType | Breadcrumb;
  drawAnnotation?: boolean;
  markers?: Marker[];
}

export const ViewPdf: React.FC<ViewPdfProps> = ({
  file,
  drawAnnotation,
  markers
}) => {

  const {
    scrolledIndex,
    setCurrentPageNumber,
    scale,
    setScaleFit,
    numPages,
    setNumPages,
    handleZoomIn,
    handleZoomOut,
    nextPage,
    prevPage,
    scaleText,
    pdfFocusRef,
    goToPage,
    setZoomLevel,
    zoomInEnabled,
    zoomOutEnabled,
    handleRotate,
    rotation
  } = usePDFViewer(file);

  const [authToken, setAuthToken] = useState("");
  const [pdfBlobUrl, setPdfBlobUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchToken = async () => {
      const accessToken = await getAccessToken();
      if (accessToken) {
        setAuthToken(accessToken);
      }
    };
    fetchToken();
  }, []);

  useEffect(() => {
    if (drawAnnotation && authToken && markers) {
      const fetchAndModifyPdf = async () => {
        try {
          const existingPdfBytes = await fetch(file?.url, {
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/pdf'
            }
          }).then(res => res.arrayBuffer());

          const formattedAnnotations = markers.map(annotation => {
            let color = rgb(1, 0, 0);

            if (annotation.is_valid) {
              color = rgb(0, 0, 1);
            }

            return {
              x1: annotation?.x1,
              y1: annotation?.y1,
              x2: annotation?.x2,
              y2: annotation?.y2,
              color: color,
              borderWidth: 1.5,
              target: 0,
              isExternal: false,
              isValid: annotation?.is_valid,
            }
          });
          const { blob } = await addLinkAnnotationsToPdf({
            existingPdfBytes: new Uint8Array(existingPdfBytes),
            annotations: formattedAnnotations,
            rotation
          });
          const modifiedPdfUrl = URL.createObjectURL(blob);
          setPdfBlobUrl(modifiedPdfUrl);

        } catch (error) {
          console.error("Error modifying PDF:", error);
        }
      };

      fetchAndModifyPdf();
    }
  }, [rotation, drawAnnotation, file, markers, authToken]);


  return (
    <div className="relative">
      {scaleText && (
        <PDFOptionsBar
          file={file}
          scrolledIndex={scrolledIndex}
          numPages={numPages}
          scaleText={scaleText}
          nextPage={nextPage}
          prevPage={prevPage}
          handleZoomIn={handleZoomIn}
          handleZoomOut={handleZoomOut}
          goToPage={goToPage}
          setZoomLevel={setZoomLevel}
          zoomInEnabled={zoomInEnabled}
          zoomOutEnabled={zoomOutEnabled}
          handleRotate={handleRotate}
        />
      )}
      <MemoizedVirtualizedPDF
        key={file?.url}
        ref={pdfFocusRef}
        file={file}
        hasPdfBlobUrl={Boolean(pdfBlobUrl)}
        pdfBlobUrl={pdfBlobUrl}
        setIndex={setCurrentPageNumber}
        scale={scale}
        setScaleFit={setScaleFit}
        setNumPages={setNumPages}
        rotation={rotation}
        isCentered={drawAnnotation}
      />
    </div>
  );
};
