import { useEffect } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import DefaultLayout from "../layout/DefaultLayout";
import { useBreadcrumb } from "../../context/breadcrumbs";
import { useRequestValidation } from "../../hooks/useRequestValidation";
import { ViewPdf } from "../pdf-viewer/ViewPdf";
import GridItem from "../basics/GridItem";
import Grid from "../basics/Grid";
import BackButton from "../basics/BackButton";
import { Marker } from "../../types";

const ValidationRequestDrawingDetail = () => {
    const { validationRequestId, drawingId } = useParams();

    const { validationRequestPageItem, hasValidationRequestPageItem } = useRequestValidation({ validation_request_id: validationRequestId, drawing_file_id: drawingId });

    const { activeBreadcrumb, addBreadcrumb, breadcrumbs } = useBreadcrumb();

    useEffect(() => {
        if (validationRequestId && drawingId && validationRequestPageItem?.sheet_number) {
            const breadcrumbExists = breadcrumbs.some(
                (breadcrumb) => breadcrumb.id === validationRequestId && breadcrumb.url.includes(drawingId)
            );
            if (!breadcrumbExists) {
                addBreadcrumb({
                    id: validationRequestPageItem?.sheet_number,
                    url: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/validationRequests/${validationRequestId}/pages/${drawingId}/pdf`,
                    name: validationRequestPageItem?.sheet_number,
                    drawingMarkerId: drawingId
                });
            }
        }
    }, [validationRequestId, drawingId, breadcrumbs, addBreadcrumb, validationRequestPageItem?.sheet_number]);

    const { validationRequestMarkers, hasValidationRequestMarkers } = useRequestValidation({ validation_request_id: validationRequestId, drawing_file_id: activeBreadcrumb?.drawingMarkerId || drawingId });

    const referenceTags = validationRequestMarkers?.reduce((acc: { valid: Marker[], invalid: Marker[] }, marker: Marker) => {
        if (marker?.is_valid) {
            acc?.valid?.push(marker);
        } else {
            acc?.invalid?.push(marker);
        }
        return { ...acc };
    }, { valid: [], invalid: [] });

    const hasInvalidReferenceTags = Boolean(referenceTags?.invalid?.length);
    const hasValidReferenceTags = Boolean(referenceTags?.valid?.length);

    return (
        <DefaultLayout isDetailPage>
            <BackButton />
            <Grid>
                <GridItem className="col-span-9">
                    <div className="shadow-md rounded-lg">
                        {Boolean(activeBreadcrumb) && (
                            <ViewPdf
                                // @ts-ignore
                                file={activeBreadcrumb}
                                drawAnnotation
                                markers={validationRequestMarkers}
                            />
                        )}
                    </div>
                </GridItem>
                <GridItem className="col-span-3">
                    {hasValidationRequestPageItem && (
                        <div className="p-6 shadow-md rounded-lg bg-light-blue font-sans h-full">
                            {Boolean(validationRequestPageItem?.sheet_title) && (
                                <div className="mb-1 text-light-gray">Title: {validationRequestPageItem?.sheet_title}</div>
                            )}

                            {Boolean(validationRequestPageItem?.sheet_title) && (
                                <div className="mb-1 text-light-gray">Sheet number: {validationRequestPageItem?.sheet_number}</div>
                            )}

                            {Boolean(validationRequestPageItem?.sheet_title) && (
                                <div className="mb-1 text-light-gray">Page number: {validationRequestPageItem?.page_number}</div>
                            )}

                            {Boolean(validationRequestPageItem?.status) && (
                                <div className="mb-1 text-light-gray">Status: {validationRequestPageItem?.status}</div>
                            )}

                            {hasValidationRequestMarkers && (
                                <>
                                    {hasInvalidReferenceTags && (
                                        <>
                                            <div className="mb-1 mt-3 text-md">Invalid tags</div>
                                            {referenceTags.invalid?.map(({
                                                id,
                                                ref_sub_number,
                                                ref_sheet_number
                                            }: Marker, index: number) => {
                                                return (
                                                    <p
                                                        key={`${id}-${index}`}
                                                        className="text-sm ml-1 text-red-500"
                                                    >
                                                        {`${ref_sheet_number ? `${ref_sheet_number}` : ""}${ref_sub_number ? `/${ref_sub_number}` : ""} (invalid reference)`}
                                                    </p>
                                                )
                                            })}
                                        </>
                                    )}

                                    {hasValidReferenceTags && (
                                        <>
                                            <div className="mb-1 mt-3 text-md">Tags</div>
                                            <>
                                                {referenceTags.valid?.map(({
                                                    id,
                                                    target,
                                                    ref_sub_number,
                                                    ref_sheet_number,
                                                }: Marker, index: number) => {
                                                    const targetUrl = target?.url;
                                                    const sameTargetUrl = Boolean(activeBreadcrumb?.url === `${process.env.REACT_APP_API_ENDPOINT}${targetUrl}/pdf`);
                                                    return (
                                                        <p
                                                            key={`${id}-${index}`}
                                                            className={clsx("text-sm ml-1 text-blue-400", !sameTargetUrl && "cursor-pointer hover:underline")}
                                                            onClick={() => {
                                                                if (!sameTargetUrl) {
                                                                    addBreadcrumb({
                                                                        id: `${ref_sheet_number}`,
                                                                        url: `${process.env.REACT_APP_API_ENDPOINT}${targetUrl}/pdf`,
                                                                        name: `${ref_sheet_number}`,
                                                                        drawingMarkerId: id
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            {`${ref_sheet_number ? `${ref_sheet_number}` : ""}${ref_sub_number ? `/${ref_sub_number}` : ""}`}
                                                        </p>
                                                    )
                                                })}
                                            </>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </GridItem>
            </Grid>
        </DefaultLayout>
    );
};

export default ValidationRequestDrawingDetail;