type Props = {
    children: React.ReactNode;
}

const Grid = ({ children }: Props) => (
    <div className="grid grid-cols-12 gap-4 p-4">
        {children}
    </div>
);

export default Grid;