import React from "react";
import clsx from "clsx";

type Props = {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

const ListItem = ({ children, className, onClick }: Props) => {
  return (
    <li
      className={clsx("relative overflow-hidden cursor-pointer hover:bg-gray-100 group", className)}
      onClick={onClick}
    >
      {children}
    </li>

  );
};

export default ListItem;
