import { useQuery } from '@tanstack/react-query';
import apiClient from '../api/apiClient';
import { USER_RESULT_QUERY_KEY } from '../constants/queryKeys';

export const useUsers = () => {
  const { data: singleUserData, isLoading: singleUserDataIsLoading } = useQuery(
    {
      queryKey: [USER_RESULT_QUERY_KEY],
      queryFn: async () => apiClient.get("/api/v1/users/me"),
      refetchOnWindowFocus: false,
    }
  );

  const isAdmin = singleUserData?.data?.role === 'admin';
  const userEmail = singleUserData?.data?.email;

  return {
    singleUserData,
    singleUserDataIsLoading,
    hasUserAdminData: isAdmin && !singleUserDataIsLoading,
    userEmail,
    hasUserEmail: Boolean(userEmail) && !singleUserDataIsLoading,
  };
};
