import Button from "./basics/Button";
import PageNotFoundIcon from "../icons/PageNotFoundIcon";
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="mt-10 flex justify-center">
        <PageNotFoundIcon className="w-[500px] h-[500px]" />
      </div>
      <div className="text-center">
        <p className="text-xl mt-4 mb-4 font-bold font-sans">Page not found!</p>
        <Button rounded onClick={() => navigate("/")}>Back to home</Button>
      </div>
    </>
  );
};

export default PageNotFound;
