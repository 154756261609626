import React from "react";

const AvatarIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 122.9 122.9"
    version="1.1"
    viewBox="0 0 122.9 122.9"
    xmlSpace="preserve"
    {...props}
  >
    <path d="M61.4 0c17 0 32.3 6.9 43.4 18 11.1 11.1 18 26.5 18 43.4 0 17-6.9 32.3-18 43.4-11.1 11.1-26.5 18-43.4 18s-32.3-6.9-43.4-18C6.9 93.8 0 78.4 0 61.4S6.9 29.1 18 18C29.1 6.9 44.5 0 61.4 0zM41.3 54.3c-1.1 0-2 .3-2.5.7-.3.2-.6.5-.7.8-.2.4-.3.8-.2 1.4 0 1.5.8 3.5 2.4 5.8l5 8c2 3.2 4.1 6.5 6.8 8.9 2.5 2.3 5.6 3.9 9.6 3.9 4.4 0 7.6-1.6 10.2-4.1 2.7-2.5 4.9-6 7-9.5l5.7-9.3c1.1-2.4 1.4-4 1.2-5-.1-.6-.8-.8-1.8-.9h-1.9c-.5 0-1 0-1.6-.1l1.9-8.6C68 48.6 57.2 37.9 42 44.2l1 10.2c-.6 0-1.2 0-1.7-.1zM18.8 95.7c7.1-2.5 19.6-3.8 25.4-7.7 1-1.3 2.1-2.9 3.1-4.3.6-.9 1.1-1.7 1.6-2.3l.3-.3c-2.4-2.5-4.4-5.5-6.3-8.5l-5-8C36 61.8 35 59.3 35 57.3c0-1 .1-1.9.5-2.6.4-.8 1-1.5 1.7-2 .4-.2.8-.5 1.2-.6-.3-4.3-.4-9.8-.2-14.4.1-1.1.3-2.2.6-3.3 1.3-4.6 4.5-8.3 8.5-10.8 1.4-.9 2.9-1.6 4.6-2.2 2.9-1.1 1.5-5.5 4.7-5.6 7.5-.2 19.8 6.2 24.6 11.4 2.8 3 4.6 7 4.9 12.3l-.3 13.1c1.4.4 2.3 1.3 2.7 2.7.4 1.6 0 3.8-1.4 6.9 0 .1-.1.1-.1.2l-5.7 9.4c-2.2 3.6-4.5 7.3-7.5 10.1l-.1.1c.4.5.8 1.1 1.2 1.7.8 1.1 1.6 2.4 2.5 3.6 5.3 4.5 19.3 5.9 26.7 8.6 7.6-9.4 12.1-21.4 12.1-34.4 0-15.1-6.1-28.8-16-38.7-9.9-9.9-23.6-16-38.7-16s-28.8 6.1-38.7 16c-9.9 9.9-16 23.6-16 38.7-.1 12.9 4.4 24.8 12 34.2zM77 90.5c-1.4-1.6-2.8-3.7-4.1-5.5-.4-.5-.7-1.1-1.1-1.5-2.7 2-6 3.3-10.3 3.3-4.5 0-8-1.6-10.9-4.1 0 0 0 .1-.1.1-.5.7-1 1.4-1.6 2.3-1.1 1.6-2.3 3.3-3.4 4.8.1 10.1 25.6 16.1 31.5.6z"></path>
  </svg>
);


export default AvatarIcon;