import React from "react";

type Props = {
  dropdownRef: React.RefObject<HTMLDivElement>;
  toggleDropdown: () => void;
  icon: React.ReactNode;
  isOpen: boolean;
  children: React.ReactNode;
}

const Dropdown = ({ dropdownRef, toggleDropdown, icon, isOpen, children }: Props) => {
  return (
    <div className="relative mr-2" ref={dropdownRef}>
      <button onClick={toggleDropdown} className="relative border-none bg-none">
        {icon}
      </button>
      {isOpen && (
        <div className="absolute top-full right-0 mt-1 w-64 z-10 bg-white rounded-lg shadow-md">
          <ul>
            {children}
          </ul>
        </div>
      )}
    </div>
  )
}

export default Dropdown;