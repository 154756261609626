import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import clsx from 'clsx';
import DefaultLayout from "../layout/DefaultLayout";
import ValidationRequestTable from "./ValidationRequestTable";
import Grid from "../basics/Grid";
import GridItem from "../basics/GridItem";
import Alert from "../basics/Alert";
import BackButton from "../basics/BackButton";
import { useRequestValidation } from "../../hooks/useRequestValidation";

type StatusBadgeProps = {
    status: "in_review";
}

const StatusBadge = ({ status }: StatusBadgeProps) => {
    const statuses = {
        'in_review': 'bg-yellow-500',
    };

    const statusesLabel = {
        'in_review': "In review"
    };

    return (
        <>
            <div>Status</div>
            <span className={clsx('inline-flex items-center text-white text-xs font-medium px-2.5 py-0.5 rounded-full', statuses[status])}>
                <span className="w-2 h-2 me-1 bg-white rounded-full"></span>
                {statusesLabel[status]}
            </span>
        </>
    );
};


const ValidationRequestDetail = () => {
    const navigate = useNavigate();
    const [drawingId, setDrawingId] = useState("");
    const { validationRequestId } = useParams();
    const {
        validationRequestItem,
        hasValidationRequestItem,
        validationRequestPages,
        hasValidationRequestPages,
        validationRequestReport,
        hasValidationRequestReport
    } = useRequestValidation({
        validation_request_id: validationRequestId,
        drawing_file_id: drawingId
    });


    const handleRowClick = (drawing_file_id: string) => {
        setDrawingId(drawing_file_id);
        navigate(`/validation-request/${validationRequestId}/${drawing_file_id}`);
    };

    return (
        <DefaultLayout isDetailPage>
            <BackButton />
            {hasValidationRequestPages && (
                <div className="pl-4  mt-2 font-bold text-md">Sheets</div>
            )}
            <Grid>
                {hasValidationRequestPages && (
                    <GridItem className="col-span-8">
                        <ValidationRequestTable
                            isDetail
                            items={validationRequestPages}
                            onRowClick={handleRowClick}
                        />
                    </GridItem>
                )}
                <GridItem className="col-span-4">
                    {hasValidationRequestItem && (
                        <div className="p-6 bg-light-blue rounded-lg shadow-md font-sans">
                            <div className="flex items-center justify-between mb-4">
                                {Boolean(validationRequestItem?.title) && (
                                    <div className="text-xl font-bold">
                                        {validationRequestItem?.title}
                                    </div>
                                )}
                            </div>

                            {Boolean(validationRequestItem?.status) && (
                                <div className="mb-4 flex justify-between">
                                    <StatusBadge status={validationRequestItem?.status} />
                                </div>
                            )}

                            {hasValidationRequestReport && (
                                <Alert type="warning" message="Missing sheets">
                                    {validationRequestReport?.map((report: string, index: number) => (
                                        <div
                                            key={`${report}-${index}`}
                                            className="flex pl-4"
                                        >
                                            <div className="flex-shrink-0 inline w-h h-4 me-3" />
                                            - {report}
                                        </div>
                                    ))}
                                </Alert>
                            )}
                        </div>
                    )}
                </GridItem>
            </Grid>
        </DefaultLayout>
    );
};

export default ValidationRequestDetail;